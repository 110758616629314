import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import authService from 'services/auth/service';
import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';

// TODO: Remove when order query hook added
import initAction from 'store/actions/OrderDetails';
import orderListAction from 'store/actions/OrdersList';

import {
  addOrder,
  downloadInvoicePdf,
  getJobsForDiversion,
  getOrderComments,
  addOrderComment,
  getOrderPrices,
  getOrders,
  divert,
  getOrder,
  modifyOrder,
  getOrderTickets,
  getDemurrageReasons,
  saveDemurrageReason,
  getOrderContainers,
  getOrderPriceBreakdown,
  getOrderTicketTypes,
  getOrderBreadcrumbs,
  uploadOrderTicket,
} from './queries';

export const useOrders = ({ params, page, pageSize }) =>
  useQuery({
    queryKey: ['orders', params, page, pageSize],
    queryFn: () => getOrders({ params, page, pageSize }),
    placeholderData: (prevData, prevQuery) => {
      // Only keep previous data if page changes
      // also keep if startDate changes and no endDate
      if (
        (params?.startDate && !params?.endDate) ||
        isEqual(prevQuery?.queryKey?.[1], params)
      ) {
        return prevData;
      }
      return undefined;
    },
    // Require a start date and an end date
    enabled: params?.startDate ? !!params?.endDate : true,
  });

export const useOrder = ({ orderId, enabled }) =>
  useQuery({
    queryKey: ['orders', Number(orderId)],
    queryFn: () => getOrder(orderId),
    enabled: enabled && !!orderId,
  });

export const useAddOrder = ({ jobId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: orderInfo => addOrder({ jobId, orderInfo }),
    onSuccess: () => {
      swal('Successfully Added Orders', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['jobs', jobId] });
      queryClient.invalidateQueries({ queryKey: ['orders'] });
    },
    onError: error =>
      swal('Unable to Add Orders', error.response?.data?.message, 'error'),
  });
};

export const useModifyOrder = ({ orderId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: orderInfo => modifyOrder({ orderId, orderInfo }),
    onSuccess: () => {
      swal('Success!', 'Successfully Modified Order', 'success');
      queryClient.invalidateQueries({ queryKey: ['orders', Number(orderId)] });
    },
    onError: error =>
      swal('Unable to Modify Order', error.response?.data?.message, 'error'),
  });
};

export const useOrderTickets = ({ orderId }) =>
  useQuery({
    queryKey: ['orders', Number(orderId), 'tickets'],
    queryFn: () => getOrderTickets(orderId),
    enabled: !!orderId,
  });

export const useUploadOrderTicket = ({ orderId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ticket => uploadOrderTicket({ orderId, ticket }),
    onSuccess: () => {
      swal('Success!', 'Successfully Uploaded Ticket', 'success');
      queryClient.invalidateQueries({ queryKey: ['orders', Number(orderId)] });
    },
    onError: error =>
      swal('Unable to Upload Ticket', error.response?.data?.message, 'error'),
  });
}

export const useOrderContainers = ({ orderId, enabled }) =>
  useQuery({
    queryKey: ['orders', orderId, 'containers'],
    queryFn: () => getOrderContainers(orderId),
    enabled: !!orderId && enabled,
  });

export const useOrderTicketTypes = ({ orderId }) =>
  useQuery({
    queryKey: ['orders', orderId, 'ticket-types'],
    queryFn: () => getOrderTicketTypes(orderId),
    enabled: !!orderId,
  });

export const useOrderPriceBreakdown = ({ orderId }) =>
  useQuery({
    queryKey: ['orders', orderId, 'price-breakdown'],
    queryFn: () => getOrderPriceBreakdown(orderId),
    enabled: !!orderId,
  });

export const useOrderBreadcrumbs = ({ orderId }) =>
  useQuery({
    queryKey: ['orders', orderId, 'breadcrumbs'],
    queryFn: () => getOrderBreadcrumbs(orderId),
    enabled: !!orderId,
  });

export const useDemurrageReasons = () =>
  useQuery({
    queryKey: ['demurrage-reasons'],
    queryFn: getDemurrageReasons,
    placeholderData: [],
  });

export const useSaveDemmurageReason = ({ orderId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: reason => saveDemurrageReason({ orderId, reason }),
    onSuccess: () => {
      swal('Success!', 'Successfully Saved Reason', 'success');
      queryClient.invalidateQueries({ queryKey: ['orders', orderId] });
    },
    onError: error =>
      swal('Unable to Save Reason', error.response?.data?.message, 'error'),
  });
};

export const useDownloadInvoicePdf = () =>
  useMutation({
    mutationFn: orderId => downloadInvoicePdf(orderId),
    onSuccess: () => {
      swal('Successfully Downloaded Order Invoice', '', 'success');
    },
    onError: error =>
      swal(
        'Unable to Download Order Invoice',
        error.response?.data?.message,
        'error',
      ),
  });

export const useOrderPrices = orderId =>
  useSuspenseQuery({
    queryKey: ['orders', orderId, 'prices'],
    queryFn: () => getOrderPrices(orderId),
    enabled: !!orderId,
  });

export const useOrderComments = ({ orderId }) =>
  useQuery({
    queryKey: ['orders', orderId, 'comments'],
    queryFn: () => getOrderComments(orderId),
    enabled: !!orderId,
  });

export const useAddOrderComment = ({ orderId }) => {
  const queryClient = useQueryClient();
  const user = authService.getUser();

  return useMutation({
    mutationFn: comment => addOrderComment({ orderId, comment }),
    onMutate: async newComment => {
      const queryKey = ['orders', orderId, 'comments'];
      // Optimistic update
      await queryClient.cancelQueries(queryKey);
      const prevComments = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, oldComments => [
        ...oldComments,
        {
          comment: newComment,
          is_system: 0,
          character_id: user.id,
          character_type: user.name,
          created_at: new Date(),
          profile_url: 'https://api.dev.automatizelabs.com/v2/documents/1098',
        },
      ]);
      return prevComments;
    },
    onSuccess: () => {
      // swal('Successfully Added Order Comment', '', 'success');
      queryClient.invalidateQueries({
        queryKey: ['orders', orderId, 'comments'],
      });
    },
    onError: error =>
      swal(
        'Unable to Add Order Comment',
        error.response?.data?.message,
        'error',
      ),
  });
};

export const useJobsForDiversion = ({
  customerId,
  equipmentId,
  jobId,
  carrierId,
  orderId,
}) =>
  useQuery({
    queryFn: () =>
      getJobsForDiversion({
        customerId,
        equipmentId,
        jobId,
        carrierId,
        orderId,
      }),
    placeholderData: [],
    queryKey: [
      'jobs-for-diversion',
      { customerId, equipmentId, carrierId, jobId },
    ],
    enabled: !!orderId,
  });

export const useDivertOrder = ({ orderId }) => {
  const queryClient = useQueryClient();

  // TODO: Remove when order query hook added
  const dispatch = useDispatch();
  const pageLocation = window.location.pathname.split('/')[2];

  return useMutation({
    mutationFn: data => divert({ orderId, ...data }),
    onSuccess: (_, data) => {
      swal(
        'Success!',
        `Successfully diverted order to job ${data.jobId}`,
        'success',
      );
      queryClient.invalidateQueries({ queryKey: ['orders', orderId] });

      // TODO: Remove when order query hook added
      if (pageLocation === 'order-details') {
        dispatch(initAction.init(orderId));
      } else {
        dispatch(orderListAction.refreshOrder(orderId));
      }
    },
    onError: error =>
      swal('Unable to Divert Order', error.response?.data?.message, 'error'),
  });
};
