import React, { useState } from 'react';
import { Modal, Image, Row } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';

import { useOrder, useUploadOrderTicket } from 'api/v2/orders';
import Loader from 'components/shared/Loader';

import {
  MAX_TICKET_WEIGHT,
  MIN_TICKET_WEIGHT,
  useContainerOptions,
  useMappedTicketTypes,
  useTicketForm,
} from './hooks';
import { generateFormData, generateImg } from './helpers';

const UploadOrderTicket = ({ orderId, openModal, turnModal }) => {
  const { data: order, isLoading } = useOrder({
    orderId,
    enabled: !!openModal,
  });
  const { mutate: uploadTicket, isPending } = useUploadOrderTicket({
    orderId,
  });

  const containers = useContainerOptions(orderId);

  const [isCropping, turnCropping] = useState(false);
  const { data: formData, handlers, isValid } = useTicketForm();

  const ticketTypes = useMappedTicketTypes(orderId);

  const handleClose = () => {
    handlers.clear();
    turnModal();
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const size = formData.pixelCrop || formData.crop;
    const image = await generateImg(formData.url, size);
    const data = generateFormData({
      ...formData,
      driverId: order?.info.driver_id,
      image,
    });
    uploadTicket(data, {
      onSuccess: handleClose,
    });
  };

  return (
    <Modal className="modal-container" show={openModal} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title as="h4">Upload Ticket</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          {isLoading ? (
            <Loader />
          ) : (
            <Row>
              {(formData.weight > MAX_TICKET_WEIGHT ||
                formData.weight < MIN_TICKET_WEIGHT) && (
                <div className="alert-input-msg">
                  Quantity should be more than{' '}
                  {MIN_TICKET_WEIGHT.toLocaleString()} and less than{' '}
                  {MAX_TICKET_WEIGHT.toLocaleString()}
                </div>
              )}
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={handlers.valueChange('ticketNo')}
                  value={formData.ticketNo}
                  label="Ticket number"
                  required
                  testSelector="order-actions_upload-st_ticket-number_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={handlers.valueChange('weight')}
                  value={formData.weight}
                  label="Quantity"
                  min="0"
                  max={MAX_TICKET_WEIGHT}
                  required
                  testSelector="order-actions_upload-st_quantity_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="file"
                  label="Ticket (jpg/png)"
                  onChange={handlers.valueChange('file')}
                  required
                  accept="image/*"
                  testSelector="order-actions_upload-st_ticket_file-input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  placeholder="Ticket Type"
                  value={ticketTypes.find(
                    ticketType => ticketType.value === formData.ticketType,
                  )}
                  onChange={handlers.valueChange('ticketType')}
                  options={ticketTypes}
                  required
                  testSelector="order-actions_upload-st_sand-ticket-type_select"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                {order.info?.equipment === 3 && (
                  <span>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      onChange={handlers.valueChange('containerIds')}
                      options={containers}
                      placeholder="Select containers"
                      value={formData.containerIds}
                      testSelector="order-actions_upload-st_container_select"
                    />
                    {Number(order.info.containersPerTruck) !==
                      formData.containerIds.length && (
                      <span>
                        Need to select {order.info.containersPerTruck}{' '}
                        {Number(order.info.containersPerTruck) === 1
                          ? 'container'
                          : 'containers'}
                      </span>
                    )}
                  </span>
                )}
              </div>
              {formData.url && (
                <div className="form-group has-feedback col-md-12">
                  {isCropping ? (
                    [
                      <Button
                        onClick={() => {
                          turnCropping(false);
                          handlers.valueChange('pixelCrop')(undefined);
                          handlers.valueChange('crop')({
                            x: 10,
                            y: 10,
                          });
                        }}
                        colour="white"
                        testSelector="order-actions_upload-st_full-suze_btn">
                        Use full size
                      </Button>,
                      !formData.crop.width && (
                        <p>
                          <small className="text-danger">
                            * please select a crop region before saving Ticket
                          </small>
                        </p>
                      ),
                    ]
                  ) : (
                    <Button
                      onClick={() => turnCropping(true)}
                      colour="white"
                      testSelector="order-actions_upload-st_crop_btn">
                      Crop a region
                    </Button>
                  )}
                  <div
                    className="form-group has-feedback col-md-12"
                    style={{ display: 'block', textAlign: 'center' }}>
                    {isCropping ? (
                      <ReactCrop
                        src={formData.url}
                        crop={formData.crop}
                        onComplete={(_, pixelCrop) =>
                          handlers.valueChange('pixelCrop')(pixelCrop)
                        }
                        onChange={handlers.valueChange('crop')}
                      />
                    ) : (
                      <Image src={formData.url} width="100%" alt="preview" />
                    )}
                  </div>
                </div>
              )}
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleClose}
            colour="white"
            testSelector="order-actions_upload-st_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={!isValid || isPending}
            testSelector="order-actions_upload-st_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default UploadOrderTicket;
