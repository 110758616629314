import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/OrderDetails';
import Authorize from 'components/common/Authorize';
import {
  missingSandTickets as missTickets,
  mappedSandTicketsToTypes as mappedTickets,
  notRequiredTicketsToTypes as notRequiredTickets,
} from 'models/OrderDetails';
import { deleteAlert } from 'components/sweetalert';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Loader from 'components/shared/Loader';
import Label from 'components/shared/Label';
import ImageViewer from 'components/shared/ImageViewer';
import { useOrder, useOrderTicketTypes } from 'api/v2/orders';
import PreviewSandTicket from './PreviewSandTicket';
import EditSandTicket from './EditSandTicket';

const SandTickets = ({
  orderId,
  selectTicket,
  clearGetST,
  clearEditST,
  deleteSandTicket,
}) => {
  const { data: orderDetails } = useOrder({ orderId });
  const { info: order, sandTicketIds, commodityInfo } = useMemo(
    () => orderDetails ?? {},
    [orderDetails],
  );
  const { data: ticketTypes, isLoading } = useOrderTicketTypes({ orderId });

  const [showPreview, turnPreview] = useState(false);
  const [showModalEditTicket, turnEditTicket] = useState(false);
  const [showImageViewer, setShowImageViewer] = useState(false);
  const [docNumber, setDocNumber] = useState(undefined);
  const [docName, setDocName] = useState('');

  const missingSandTickets = missTickets(sandTicketIds, ticketTypes);
  const mappedSandTicketsToTypes = mappedTickets(sandTicketIds, ticketTypes);
  const notRequiredTicketsToTypes = notRequiredTickets(
    sandTicketIds,
    ticketTypes,
  );

  const renderTicketId = (item, index) => (
    <div key={`sandTicketIds-${item.ticket_number}-${index}`}>
      <Button
        onClick={() => {
          turnPreview(true);
          selectTicket(item);
        }}
        theme="small"
        colour="green"
        testSelector="order-details_sand-tickets_preview_btn">
        {ticketTypes.map(
          type => type.sandTicketTypeId === item.sand_ticket_type && type.name,
        )}{' '}
        #{item.ticket_number || 'unknown'}
      </Button>
      <Authorize
        itemAccessGranted={!order.is_deleted}
        abilityPermissions={[{ resource: 'Orders', permissions: ['update'] }]}>
        <Button
          onClick={() => {
            turnEditTicket(true);
            selectTicket(item);
          }}
          testSelector="order-details_sand-tickets_preview_btn"
          theme="small">
          <Icon icon="pencil" />
        </Button>
      </Authorize>
      <Authorize
        itemAccessGranted={!order.is_deleted}
        abilityPermissions={[{ resource: 'Orders', permissions: ['update'] }]}>
        <Button
          onClick={() => deleteAlert(deleteSandTicket, item.id)}
          testSelector="order-details_sand-tickets_delete_btn"
          theme="small">
          <Icon icon="trash" />
        </Button>
      </Authorize>
    </div>
  );

  const renderTicketItem = (item, index) => (
    <div className="row" key={`sandTicketTypes-${index}`}>
      <div className="col-md-6 col-sm-12">
        <li style={{ height: 30 }}>
          <Label className="ticket-danger" type="danger">
            {item.sandTicketType.name}
          </Label>
        </li>
      </div>
      <div className="col-md-6 col-sm-12" style={{ marginBottom: '10px' }}>
        {!!item.sandTicketIds?.length && item.sandTicketIds.map(renderTicketId)}
      </div>
    </div>
  );

  const renderCommodityItem = (item, index) => (
    <div key={`commodityInfo-${item.name}-${index}`}>
      <div className="row">
        <div className="col-md-6 col-sm-12" />
        <div className="col-md-6 col-sm-12">
          <Button
            onClick={() => {
              setShowImageViewer(x => !x);
              setDocNumber(Number(item.documents));
              setDocName(item.name);
            }}
            theme="small"
            colour="green"
            testSelector="order-details_commodity_documents_preview_btn">
            {`${item.name}`}
          </Button>
        </div>
      </div>
    </div>
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="row" style={{ marginRight: '0px' }}>
        <div className="col-md-12 col-sm-12">
          <small>
            <strong>Commodity Info: </strong>
          </small>
          <ul className="unstyled">
            {(commodityInfo ?? []).map(renderCommodityItem)}
          </ul>
        </div>
      </div>

      <div className="row" style={{ marginRight: '0px' }}>
        <div className="col-md-12 col-sm-12">
          <small>
            <strong>Required Tickets: </strong>
          </small>
          <ul className="unstyled">
            {mappedSandTicketsToTypes.map(renderTicketItem)}
          </ul>
        </div>
        {!!order.hasMissingSandTickets && missingSandTickets.length ? (
          <div className="col-md-12 col-sm-12" style={{ marginTop: '5px' }}>
            <small>
              <strong>Missing Tickets: </strong>
            </small>
            <ul className="unstyled">
              {missingSandTickets.map((item, index) => (
                <li key={`missingSandTickets-${index}`}>
                  <Label className="ticket-danger" type="danger">
                    {item}
                  </Label>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        {notRequiredTicketsToTypes.length ? (
          <div className="col-md-12 col-sm-12" style={{ marginTop: '5px' }}>
            <small>
              <strong>Not Required Tickets: </strong>
            </small>
            <ul className="unstyled">
              {notRequiredTicketsToTypes.map((item, index) => (
                <div className="row" key={`notRequiredTicketsToTypes-${index}`}>
                  <div className="col-md-6 col-sm-12">
                    <li>
                      <Label className="ticket-danger" type="danger">
                        {item.sandTicketType.name}
                      </Label>
                    </li>
                  </div>
                  <div
                    className="col-md-6 col-sm-12"
                    style={{ marginBottom: '10px' }}>
                    {(item.sandTicketIds ?? []).map(renderTicketId)}
                  </div>
                </div>
              ))}
            </ul>
          </div>
        ) : null}
      </div>

      {showPreview && (
        <PreviewSandTicket
          orderId={orderId}
          showPreview={showPreview}
          closePreview={() => {
            turnPreview(false);
            clearGetST();
          }}
        />
      )}
      {showImageViewer && (
        <ImageViewer
          visibleToggle={setShowImageViewer}
          docId={docNumber}
          docName={docName}
          visible={showImageViewer}
        />
      )}
      {showModalEditTicket && (
        <EditSandTicket
          showModalEditTicket={showModalEditTicket}
          closeEditTicket={() => {
            turnEditTicket(false);
            clearEditST();
          }}
          orderId={orderId}
          sandTicketTypes={ticketTypes}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  getSandTicketsDesign: () => dispatch(actions.getSandTicketsDesign()),
  selectTicket: item => dispatch(actions.selectTiket(item)),
  clearGetST: () => dispatch(actions.clearGetST()),
  clearEditST: () => dispatch(actions.clearEditST()),
  deleteSandTicket: id => dispatch(actions.deleteSandTicket(id)),
});

export default connect(
  null,
  mapDispatchToProps,
)(SandTickets);
